<template>
  <div>
    <!-- 顶部搜索栏 -->
    <el-row>
      <el-col :xs="24" :sm="24" :lg="24">
        <div class="pad-container flx-row ali-c">
          <el-input
            placeholder="输入商品名称或条形码"
            class="search-input mr-10"
            v-model="searchObj.goodsName"
          ></el-input>
          <el-cascader
            placeholder="请选择商品分类"
            class="form-input-200 mr-10"
            v-model="searchObj.goodsClassifyId"
            :options="classificationList"
            :props="{ expandTrigger: 'hover' }"
          >
          </el-cascader>
          <el-select
            v-model="searchObj.merchantId"
            filterable
            placeholder="请选择商户"
            class="form-input-200 mr-10"
          >
            <el-option
              v-for="item in merchantList"
              :key="item.merchantId"
              :label="item.merchantName"
              :value="item.merchantId"
            >
            </el-option>
          </el-select>
          <el-select
            placeholder="专区类型"
            class="search-input ml-15"
            v-model="searchObj.areaType"
          >
            <el-option
              v-for="(item, index) in areaTypeList"
              :key="index"
              :label="item.title"
              :value="item.id"
            ></el-option>
          </el-select>
          <el-select
            placeholder="请选择商品状态"
            class="search-input ml-15"
            v-model="searchObj.goodsStatus"
          >
            <el-option
              v-for="(item, index) in goodsStatusOptions"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
          <el-button
            type="primary"
            icon="el-icon-search"
            class="ml-15"
            @click="search"
            >查询</el-button
          >
          <el-button icon="el-icon-refresh" class="ml-15" @click="reset"
            >重置</el-button
          >
        </div>
      </el-col>
    </el-row>
    <!-- 表格内容 -->
    <el-row class="mt-24">
      <el-col el-col :xs="24" :sm="24" :lg="24">
        <div class="pad-container">
          <!-- 操作栏 -->
          <div class="flx-row ali-c js-fs">
            <div class="flx-row ali-c">
              <router-link
                :to="{ name: 'Online_Commodity_new', query: { flag: true } }"
              >
                <el-button class="ml-15" type="primary">添加商品</el-button>
              </router-link>
            </div>
            <div class="flx-row ali-c">
              <el-button
                class="ml-15"
                type="primary"
                plain
                @click="exportStoreInformation"
                >门店信息导出</el-button
              >
            </div>
            <!-- 先隐藏掉 -->
            <!-- <div class="flx-row ali-c">
                    <el-upload
              class="upload-demo ml-10"
              accept=".xls,.xlsx"
              :action="urlUp"
              :limit="1"
              :show-file-list="false"
              :on-success="handleSuccess"
              ref="upload"
            >
              <el-button type="primary" plain>商品一键导入</el-button>
            <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div> 
            </el-upload>
            </div> -->
          </div>
          <!-- 门店列表 -->
          <el-tabs
            v-model="searchObj.storeId"
            @tab-click="changeTab"
            class="mt-20"
          >
            <el-tab-pane
              :label="item.storeName"
              :name="item.id + ''"
              v-for="(item, index) in shopList"
              :key="index"
            >
            </el-tab-pane>
          </el-tabs>
          <!-- 表格 -->
          <el-table
            class="mt-24 member-table"
            :data="tableData"
            @selection-change="tableSelect"
          >
            <el-table-column
              type="index"
              :index="indexMethod"
              label="序号"
              width="100"
              align="center"
            ></el-table-column>
            <el-table-column
              label="商品ID"
              prop="goodsId"
            ></el-table-column>
            <el-table-column
              label="商品名称"
              prop="goodsName"
            ></el-table-column>
            <el-table-column
              label="商品条形码"
              prop="barCode"
            ></el-table-column>
            <el-table-column
              label="一级分类"
              prop="firstClassifyName"
            ></el-table-column>
            <el-table-column
              label="二级分类"
              prop="secondClassifyName"
            ></el-table-column>
            <el-table-column label="实价" prop="price"></el-table-column>
            <el-table-column label="原价" prop="markPrice"></el-table-column>
            <el-table-column label="商户" prop="merchantName"></el-table-column>
            <el-table-column label="销量" prop="sales"></el-table-column>
            <el-table-column label="剩余库存" prop="stock"></el-table-column>
            <el-table-column label="排序" prop="sort" v-if="!isAreaType">
              <template slot-scope="scope">
                <div>
                  {{ scope.row.sort }}
                  <i
                    class="ml-10 el-icon-edit-outline  txt-tip-info"
                    @click="openEdit(scope.row)"
                  />
                </div>
              </template>
            </el-table-column>
            <el-table-column label="专区类型" v-if="isAreaType">
              <template>
                {{ areaTypeList[searchObj.areaType - 1].title }}
              </template>
            </el-table-column>
            <el-table-column label="专区排序" prop="areaSort" v-if="isAreaType">
              <template slot-scope="scope">
                <div>
                  {{ scope.row.areaSort }}
                  <i
                    class="ml-10 el-icon-edit-outline tip-text-delete txt-tip-info"
                    @click="openEdit(scope.row)"
                  />
                </div>
              </template>
            </el-table-column>
            <el-table-column label="状态" width="200">
              <template slot-scope="scope">
                <el-switch
                  v-model="scope.row.goodsStatus"
                  :active-value="1"
                  :inactive-value="0"
                  active-text="上架"
                  inactive-text="下架"
                  @change="
                    upDownShelf(scope.row.goodsStatus, scope.row.goodsId)
                  "
                ></el-switch>
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <div class="flx-row ali-c">
                  <router-link
                    :to="{
                      name: 'Online_Commodity_new',
                      query: { id: scope.row.goodsId, flag: false },
                    }"
                    class="txt-tip-info"
                  >
                    <i class="el-icon-edit-outline"></i>
                    编辑
                  </router-link>
                  <span
                    class="ml-10 tip-text-delete"
                    @click="deleteCommodity(scope.row.goodsId)"
                    v-if="rolesMessage.userRoleVos[0].type != 4"
                  >
                    <i class="el-icon-delete"></i>
                    删除
                  </span>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="flx-row ali-c js-c mt-24">
            <Pagination
              :total="total"
              :pageNum="pageNum"
              :pageSize="pageSize"
              :pageSizes="pageSizes"
              @changePage="getCommodityList"
              @syncPageData="syncPageData"
            />
          </div>
        </div>
      </el-col>
    </el-row>

    <!-- 编辑排序弹窗 start -->
    <el-dialog :visible.sync="dialog" :title="title" @close="cancel">
      <el-form
        :model="form"
        :rules="rules"
        ref="form"
        label-position="right"
        label-width="100px"
      >
        <el-form-item prop="sort" label="排序">
          <el-input v-model="form.sort" placeholder="请输入排序数字"></el-input>
        </el-form-item>
      </el-form>
      <div class="flx-row ali-c js-c mt-50">
        <el-button @click="cancel">取消</el-button>
        <el-button type="primary" class="ml-10" @click="confirm('form')"
          >提交</el-button
        >
      </div>
    </el-dialog>
    <!-- 审核弹窗 end -->
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import {
  getProcudtList,
  getStoreList,
  getTypeList,
  getMerchantsListByStoreId,
  subSort,
  productUpShelf,
  productDownShelf,
  productDelete,
} from "@/api/online_department_store/commodity_manage";
import { getProcudtCateListAll } from "@/api/online_department_store/categories_manage";
export default {
  name: "Online_commodity_list",
  components: {
    Pagination,
  },
  data() {
    return {
      // 顶部搜索栏数据
      searchObj: {
        goodsName: "",
        goodsClassifyId: [],
        storeId: "",
        merchantId: "",
        areaType: "", //专区类型：1.热卖单品 2.猜你喜欢 3.首发新品 4.大牌推荐 5.推荐2区
        goodsStatus: "", //状态（0.下架 1.上架）
      },
      classificationList: [],
      store_list: [],
      merchantList: [],
      // 商品状态下拉选项
      goodsStatusOptions: [
        { id: 0, name: "下架" },
        { id: 1, name: "上架" },
      ],
      areaTypeList: [
        {
          id: 1,
          title: "热卖单品",
        },
        {
          id: 2,
          title: "猜你喜欢",
        },
        {
          id: 3,
          title: "首发新品",
        },
        {
          id: 4,
          title: "大牌推荐",
        },
        {
          id: 5,
          title: "推荐2区",
        },
      ],
      form: {
        sort: "",
      },
      rules: {
        sort: [
          { required: true, message: "排序不能为空", trigger: "change" },
          {
            pattern: /^[1-9]\d{0,}$/,
            message: "只能为正数字",
            trigger: "change",
          },
        ],
      },
      dialog: false,
      activePart: "1",
      isAreaType: false,
      title: "编辑",
      shopList: [],
      // 表格数据内容
      tableData: [],
      total: 0,
      pageNum: 1,
      pageSize: 10,
      pageSizes: [10, 20, 30, 50, 100],
      url: "/small-inteface/goods-service/goods/exportAttr", //门店信息导出地址
      urlUp: "", //商品一键导入地址
      rolesMessage:{},
    };
  },
  mounted() {
    this.rolesMessage = this.$store.state.user.userObject
    console.log(this.$store.state.user)
    this.urlUp = process.env.VUE_APP_BASE_API + "/small-inteface/goods-service/goods/import";
    this.getStores();
    this.getCateList();
  },
  methods: {
    //查询商品列表
    getCommodityList(isRet) {
      if (isRet) {
        this.pageNum = 1;
        this.pageSize = 10;
      }
      let search = JSON.parse(JSON.stringify(this.searchObj));
      search.goodsClassifyId =
        search.goodsClassifyId[search.goodsClassifyId.length - 1];

      let data = { pageNum: this.pageNum, pageSize: this.pageSize, ...search };
      console.log(data);
      getProcudtList(data).then((res) => {
        console.log(res.data.body);
        this.tableData = res.data.body.list;
        this.total = res.data.body.total;
        this.isAreaType = search.areaType ? true : false;
      });
    },
    confirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.subSortHanlde();
        } else {
          return false;
        }
      });
    },
    //门店信息导出
    exportStoreInformation() {
      this.downFile(this.url, "门店信息", {}, "get");
    },
    //商品一键导入地址
    handleSuccess(response, file, fileList) {
      // console.log(file);
      this.$confirm(file.name, "一键导入百货商品", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "success",
      })
        .then(() => {
          this.$refs.upload.submit();
          this.$message({
            type: "success",
            message: "导入成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消导入",
          });
        });
    },
    indexMethod(index) {
      // 自定义排序号
      return index + (this.pageNum - 1) * this.pageSize + 1;
    },
    cancel() {
      this.$refs.form.resetFields();
      this.dialog = false;
    },
    openEdit(row) {
      // 编辑排序弹窗出现
      if (this.isAreaType) {
        this.title =
          this.areaTypeList.find((ele) => ele.id == this.searchObj.areaType)
            .title + "排序编辑";
      }
      this.dialog = true;
      this.form.goodsId = row.goodsId;
    },
    //提交排序
    subSortHanlde() {
      let data = JSON.parse(JSON.stringify(this.form));
      this.dialog = false;
      if (this.isAreaType) {
        data.areaType = this.searchObj.areaType;
        data.areaSort = data.sort;
      }
      subSort(data).then((res) => {
        this.getCommodityList();
      });
    },
    // 获取门店列表
    getStores() {
      getStoreList().then((res) => {
        if(this.rolesMessage && this.rolesMessage.userRoleVos[0].type == 4){
          let shopList = []
          res.data.body.list.filter(item=>{
            if(item.id == this.rolesMessage.storeId){
              shopList.push(item)
            }
          })
          this.shopList = shopList
          this.searchObj.storeId = this.shopList[0].id.toString();
        }else{
          this.shopList = res.data.body.list;
          this.searchObj.storeId = this.shopList[0].id.toString();
        }
        this.getCommodityList(1);
        this.getMerchantsListHandle(); //获取商户
      });
    },
    // 获取商品分类
    getCateList() {
      getProcudtCateListAll({ filter: 0 }).then((res) => {
        if (res.data.code == 200) {
          this.classificationList = res.data.body;
        }
      });
    },
    //获取商户列表
    getMerchantsListHandle() {
      getMerchantsListByStoreId({ shopId: this.searchObj.storeId }).then((res) => {
        if (res.data.body && res.data.body.length) {
          this.merchantList = res.data.body;
        }
      });
    },
    // 门店改变
    changeTab(tab, event) {
      // console.log(tab)
      this.searchObj.storeId = tab.name;
      this.getMerchantsListHandle();
      this.getCommodityList(1);
    },
    // 搜索事件
    search() {
      this.getCommodityList(1);
    },
    // 重置搜索关键词
    reset() {
      this.searchObj = {
        goodsName: "",
        goodsClassifyId: [],
        storeId: String(this.shopList[0].id),
        merchantId: "",
        areaType: "",
        goodsStatus: "",
      };
      this.getCommodityList(1);
    },
    // 会员多选事件
    tableSelect(e) {
      this.multiSeletction = e;
    },
    //上下架
    async upDownShelf(status, id) {
      if (status == 0) await productDownShelf(id);
      else await productUpShelf(id);
      this.getCommodityList();
    },
    deleteCommodity(id) {
      this.$confirm("此操作将永久删除该商品, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          productDelete(id).then(() => {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getCommodityList();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    syncPageData(data) {
      this.pageNum = data.num;
      this.pageSize = data.size;
    },
  },
};
</script>
<style lang="scss" scoped>
.member-table {
  .circle {
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }
}
.txt-tip-info {
  color: #409eff;
}
</style>
